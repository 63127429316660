import { Component, computed, input } from '@angular/core';
import { BusinessCitationStatuses } from '../../models/enums/business-citation-statuses.enum';
import { LocationService } from '../../location.service';
import { BaseComponent } from '../../../base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-facebook-status',
  templateUrl: './facebook-status.component.html',
  styleUrl: './facebook-status.component.scss'
})
export class FacebookStatusComponent extends BaseComponent {

  facebookStatus = input<number>();
  hasChartData = input<boolean>();
  locationId = input<number>();


  facebookConnectionProcessing = computed<boolean>(() => this.facebookStatus() === BusinessCitationStatuses.Updating && !this.hasChartData());
  noFacebookConnection = computed<boolean>(() => this.facebookStatus() === BusinessCitationStatuses.Unknown);
  noDataProvided = computed<boolean>(() => this.facebookStatus() === BusinessCitationStatuses.Synced && !this.hasChartData());
  isVisible = computed<boolean>(() => this.facebookConnectionProcessing() || this.noFacebookConnection() || this.noDataProvided());

  constructor(private locationService: LocationService) {
    super();
  }

  refresh() {
    window.location.reload();
  }

  connectFacebook(): void {
    this.locationService.connectFacebook(this.locationId())
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        window.open(x, '_blank', 'popup=true,width=800,height=600');
      });
  }
}
