import { Component, Input, OnInit } from '@angular/core';
import { ChartData } from '../../../shared/models/chartData';
import { BaseComponent } from '../../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { ChartColorService } from '../../../shared/chart-colors.service'
import { ChartLabelService } from '../../../shared/chart-label.service'
import { ChartConfiguration, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { FacebookImpressionsByDate } from '../../models/facebook-impressions-by-date';

@Component({
  selector: 'app-facebook-impressions-by-date',
  templateUrl: './facebook-impressions-by-date.component.html',
  styleUrl: './facebook-impressions-by-date.component.scss'
})
export class FacebookImpressionsByDateComponent extends BaseComponent implements OnInit {
  @Input() facebookStatus: number;
  @Input() locationId: number;
  @Input() graphSubtitle: string;
  @Input()
  set facebookImpressionsByDate(facebookImpressionsByDate: FacebookImpressionsByDate) {
    this._chartData = facebookImpressionsByDate.facebookImpressionsMetrics;

    if (!this._chartData.hasData) {
      this._chartData = this.disconnectedDataSet;
      this.options.scales.y.max = 100;
    } else {
      this.options.scales.y.max = null;
    }

    this.translateLabels(this.chartData);
    this.totalImpressions = facebookImpressionsByDate.totalImpressions;
    this.labels = this.chartData.labels;
    this.datasets = this.chartData.dataSet;
    this.datasets.forEach((dataset, i) => {
      this.data.datasets[i].data = dataset.data;
      this.data.datasets[i].label = dataset.label;
    });
    this.data.labels = this.labels;
  }

  totalImpressions: number;
  labels;
  datasets: any[];
  chartColors: Array<any>;
  data: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: true,
        tension: 0.4,
      }
    ]
  };
  options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode:'index',
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.parsed.y}`;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        min: 0,
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : '';
          }
        }
      }
    }
  };

  private _chartData: ChartData;
  private disconnectedDataSet = {
    "hasData": false,
    "labelKeys": this.chartLabelService.getPreviousMondays(13),
    "labels": null,
    "dataSet": [
      {
        "label": null,
        "labelKey": "local_listings.facebook_impressions",
        "data": []
      }
    ]
  };

  constructor(private translate: TranslateService,
    private chartColorService: ChartColorService,
    private chartLabelService: ChartLabelService) {
    super();
  }

  ngOnInit() {
    this.chartColorService.filledLine
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
        this.data.datasets.forEach((x, index) => {
          x.backgroundColor = this.chartColors[index].backgroundColor;
          x.borderColor = this.chartColors[index].borderColor;
          x.borderWidth = this.chartColors[index].borderWidth;
        });
      });
  }

  get chartData(): ChartData {
    return this._chartData;
  }

  private translateLabels(chartData: ChartData) {
    chartData.labels = [];
    for (const i of chartData.labelKeys) {
      chartData.labels.push(moment(i).format('L'));
    }

    for (const i of chartData.dataSet) {
      i.label = this.translate.instant(i.labelKey);
    }
  }
}
