<div class="card text-center">
  <div class="card-body d-flex flex-column">
    <h4 class="card-title text-primary text-center custom-tile-header">
      {{ 'local_listings.title_visibility_index' | translate }}
      <small>
        <helpPopover placement='top' html="{{ 'local_listings.help_visibility_index' | translate }}"></helpPopover>
      </small>
    </h4>
    <h6 class="text-secondary">{{ 'local_listings.label_visibility_index_score' | translate }} {{ score }}</h6>
    <div class="row">
      <div class="col-12">
        <div class="p-2 text-center text-md-left d-flex flex-column h-100">
          @if (true) { <!-- for some reason, if you remove this if, the colors binding in the canvas breaks -->
            <canvas class="chart-canvas" baseChart
                  [data]="data"
                  [labels]="labels"
                  [options]="options"
                  [colors]="chartColors"
                  [type]="'line'"></canvas>
          }
        </div>
      </div>
    </div>
  </div>
</div>