<div *ngIf="isVisible()" class="offset-2 col-8 text-center overlap-chart">
  <div *ngIf="facebookConnectionProcessing()">
    <ng-template *ngTemplateOutlet="connectFacebook"></ng-template>
    <h4>{{ 'local_listings.facebook_processing' | translate}}</h4>
    <h4><a class="fa fa-redo text-success clickable" role="button" (click)="refresh()"></a></h4>
    <h5>{{ 'local_listings.facebook_processing_contact' | translate }}</h5>
  </div>
  <div *ngIf="noFacebookConnection()">
    <ng-template *ngTemplateOutlet="connectFacebook"></ng-template>
    <h4>{{ 'local_listings.facebook_connect_for_insights' | translate }}</h4>
    <h4><a class="fa fa-redo text-success clickable" role="button" (click)="refresh()"></a></h4>
  </div>
  <div *ngIf="noDataProvided()">
    <h4>{{ 'local_listings.no_chart_data_provided' | translate }}</h4>
    <h5>{{ 'local_listings.facebook_processing_contact' | translate }}</h5>
  </div>
</div>

<ng-template #connectFacebook>
  <button class="float-end" (click)="connectFacebook()" type="button" class="btn p-2 btn-secondary btn-lg mb-2">
    <span class="ms-2 me-2">
      <img [src]="'/assets/facebook-f.png'" class="me-2 fb-icon"/>{{ 'content_connect_account' | translate }}
    </span>
  </button>
</ng-template>